import ReactDOM from 'react-dom/client';
import './index.scss';
import "./asset/scss/style.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import config from './constant/config';
import { AuthProvider } from 'routes/auth.provider';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { KeycloackContextProvider } from 'pages/authentication/keycloak/useKeyCloak';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={config.basename}>
        <KeycloackContextProvider >
          <AuthProvider>
            <ToastContainer />
            <App />
          </AuthProvider>
        </KeycloackContextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();