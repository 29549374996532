import { IPopupItems } from "interface/redux.interface";

export const adminPopupItem: IPopupItems[] = [
  {
    path: `/profile_details`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
    isChangePassword: true
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const profilePopupItem: IPopupItems[] = [
  {
    path: `/profile_details`,
    title: "Profile",
    icon: "profile",
  },
  {
    title: "My Wallet",
    icon: "wallet",
    isWallet:true
  },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
    isChangePassword: true
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];