import { DigitalWalletRole } from "utils/constant.utils";

const admin = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
	appName: 'My Wallet',
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard/coming_soon`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard/coming_soon`],
    },
    {
      id: `services`,
      title: `Services`,
      type: `collapse`,
      url: `${process.env.REACT_APP_DC_REDIRECTION_URL}login?url=/profile/services_menu`,
      href: true,
      icon: `Dns`,
      breadcrumbs: false,
      activeItem: [`/services/coming_soon`],
    },
    {
      id: `member_portal`,
      title: `Member Portal`,
      type: `item`,
      url: `/member_portal/coming_soon`,
      icon: `userPerson`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/member_portal/coming_soon`],
    },
    {
      id: `my_business`,
      title: `My Business`,
      type: `item`,
      url: `/my_business/coming_soon`,
      icon: `AccountBalance`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/my_business/coming_soon`],
    },
    {
      id: `reports`,
      title: `Reports`,
      type: `collapse`,
      url: `${process.env.REACT_APP_IR_REDIRECTION_URL}login?url=/reports/admin/incidents`,
      icon: `userReport`,
      breadcrumbs: false,
      href: true,
      activeItem: [`/reports/coming_soon`],
      children: [
        {
          id: `reports-incidents`,
          title: `Incidents`,
          type: `item`,
          url: `${process.env.REACT_APP_IR_REDIRECTION_URL}login?url=/reports/admin/incidents`,
          icon: `ReportFile`,
          parentId: "reports",
          href: true,
        },
        {
          id: "legal_services_reports",
          title: "Legal Services Reports",
          type: "item",
          url: `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/Reports`,
          icon: "userReport",
          parentId: "reports",
          href: true,
        },
      ],
    },
    {
      id: `siteadministration`,
      title: `Site Admin`,
      type: `collapse`,
      url: `/${DigitalWalletRole.ADMIN}/airdrops_list`,
      icon: `ManageAccounts`,
      breadcrumbs: false,
      activeItem: [
        `/${DigitalWalletRole.ADMIN}/airdrops_list`,
        `/${DigitalWalletRole.ADMIN}/airdrop_create`,
        `/${DigitalWalletRole.ADMIN}/airdrop_coupon_view`,
        `/${DigitalWalletRole.ADMIN}/airdrop_token_view`,
        `/${DigitalWalletRole.ADMIN}/airdrop_edit`,
        `/${DigitalWalletRole.ADMIN}/coupons_list`,
        `/${DigitalWalletRole.ADMIN}/coupon_create`,
        `/${DigitalWalletRole.ADMIN}/coupon_edit`,
        `/${DigitalWalletRole.ADMIN}/coupon_view`,
        `/${DigitalWalletRole.ADMIN}/coupon_history`,
        `/${DigitalWalletRole.ADMIN}/coupon_redeemed`,
        `/${DigitalWalletRole.ADMIN}/tokens_list`,
        `/${DigitalWalletRole.ADMIN}/token_history`,
        `/${DigitalWalletRole.ADMIN}/coupon_categories`,
        `/${DigitalWalletRole.ADMIN}/coupon_categories_create`,
        `/${DigitalWalletRole.ADMIN}/coupon_categories_view`,
        `/${DigitalWalletRole.ADMIN}/coupon_categories_edit`,
      ],
      children: [
        {
          id: `adminusers`,
          title: `Admin Users`,
          type: `item`,
          url: `${process.env.REACT_APP_DC_REDIRECTION_URL}auth_verification_token?url=/admin/siteadministration/adminusers`,
          icon: `PeopleAlt`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: true,
          activeItem: [`/coming_soon_1`],
        },
        {
          id: `documenttemplate`,
          title: `Document Template`,
          type: `item`,
          url: `/coming_soon_2`,
          icon: `description`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: true,
          activeItem: [`/coming_soon_2`],
        },
        {
          id: "location",
          title: "Legal Services Master",
          type: "item",
          url: `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/master/service`,
          icon: "location",
          href: true,
          parentId: "master",
        },
        {
          id: `airdrops`,
          title: `Airdrops`,
          type: `item`,
          url: `/${DigitalWalletRole.ADMIN}/airdrops_list`,
          icon: `Workspace`,
          parentId: "siteadministration",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/${DigitalWalletRole.ADMIN}/airdrops_list`,
            `/${DigitalWalletRole.ADMIN}/airdrop_create`,
            `/${DigitalWalletRole.ADMIN}/airdrop_coupon_view`,
            `/${DigitalWalletRole.ADMIN}/airdrop_token_view`,
            `/${DigitalWalletRole.ADMIN}/airdrop_edit`,
          ],
        },
        {
          id: `coupns`,
          title: `Coupons`,
          type: `item`,
          url: `/${DigitalWalletRole.ADMIN}/coupons_list`,
          icon: `LocalOffer`,
          parentId: "siteadministration",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/${DigitalWalletRole.ADMIN}/coupons_list`,
            `/${DigitalWalletRole.ADMIN}/coupon_create`,
            `/${DigitalWalletRole.ADMIN}/coupon_edit`,
            `/${DigitalWalletRole.ADMIN}/coupon_view`,
            `/${DigitalWalletRole.ADMIN}/coupon_history`,
            `/${DigitalWalletRole.ADMIN}/coupon_redeemed`,
          ],
        },
        {
          id: `tokens`,
          title: `Tokens`,
          type: `item`,
          url: `/${DigitalWalletRole.ADMIN}/tokens_list`,
          icon: `TokenIcon`,
          parentId: "siteadministration",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/${DigitalWalletRole.ADMIN}/tokens_list`,
            `/${DigitalWalletRole.ADMIN}/token_history`,
          ],
        },
        {
          id: `coupon_categories`,
          title: `Coupon Categories`,
          type: `item`,
          url: `/${DigitalWalletRole.ADMIN}/coupon_categories`,
          icon: `CategoryIcon`,
          parentId: "siteadministration",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/${DigitalWalletRole.ADMIN}/coupon_categories`,
            `/${DigitalWalletRole.ADMIN}/coupon_categories_create`,
            `/${DigitalWalletRole.ADMIN}/coupon_categories_view`,
            `/${DigitalWalletRole.ADMIN}/coupon_categories_edit`,
          ],
        },
        {
          id: `resources`,
          title: `Resources`,
          type: `item`,
          url: `${process.env.REACT_APP_IR_REDIRECTION_URL}login?url=/site_admin/admin/resources_list`,
          icon: `Settings`,
          parentId: "siteadministration",
          href: true,
        },
      ],
    },
  ],
};

export default admin;
