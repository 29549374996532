import { booleanToStringsInObject, dateFnc, formatSsn, formatZipcode, parsePhoneNumber, parsePhoneNumberformat } from "forms/hooks/customHook";
import dayjs from "dayjs";
import { IPaginationArray } from "./interface.helper";

export const errorMessage = (error: any, defaultMessage: string): any => {
  if (error?.response?.data?.message) {
    if (Array.isArray(error?.response?.data?.message)) {
      return error?.response?.data?.message[0];
    }
    return error?.response?.data?.message;
  } else if (error?.data?.message) {
    return error?.data?.message;
  }
  return defaultMessage;
};

export const findObject = (value: any, key: any, arrayData: any) => {
  try {
    const findObject = arrayData.find((item: any) => item[key] === value);
    return findObject;
  } catch (error) {
    return null;
  }
}

export const UTCDateFormate = (date: any) => {
  try {
    const year = date.year();
    const month = date.month(); // Note: month is 0-indexed in JavaScript Date
    const day = date.date();

    // Create a new Date object in UTC
    const utcDate = new Date(Date.UTC(year, month, day));
    return utcDate.toISOString();
  } catch (error) {
    return null;
  }
}

export const getDriverFormDetails = (data: any) => {
  return {
    firstname: data?.firstname,
    middlename: data?.middlename,
    lastname: data?.lastname,
    email: data?.email,
    gender: data?.gender,
    ssn: data?.ssn,
    dob: data?.dob ? dayjs(data?.dob) : null as any,
    mobile_number: data?.mobile_number,
    home_phone: data?.home_phone,
    office_phone: data?.office_phone,
    motorist_id_licensing_state: data?.motorist_id_licensing_state
      ? ({ name: data?.motorist_id_licensing_state, value: data?.motorist_id_licensing_state } as any)
      : null,
    driver_license_number: data?.driver_license_number,
    license_issue_date: data?.license_issue_date ? dayjs(data?.license_issue_date) : null as any,
    license_expiration_date: data?.license_expiration_date ? dayjs(data?.license_expiration_date) : null as any,
    license_type: data?.license_type
      ? ({ name: data?.license_type, value: data?.license_type } as any)
      : null,
    hack_license_expiration_date: data?.hack_license_expiration_date ? dayjs(data?.hack_license_expiration_date) : null as any,
    ddc: data?.ddc,
    ddc_file_name: data?.ddc_file_name,
    address_line_1: data?.address_line_1,
    city: data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: data?.zip_code,
    experience: data?.experience,
    hack_license_number: data?.hack_license_number,
    address_line_2: data?.address_line_2,
    base_name: data?.base_name? data?.base_name: null,
    defensive_credit: data?.defensive_credit,
    safe_credit: data?.safe_credit,
    email_verified: data?.email_verified,
    mobile_verified: data?.mobile_verified
  };
};

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const sortData = (
  array: any[],
  key: string,
  sortBy: string,
  type?: string
) => {
  // return array.sort((a, b) => compare(a, b, key, sortBy));
  if (sortBy === "asc") {
    if (type === "number") {
      return array.sort((a, b) => a[key] - b[key]);
    }
    return array.sort((a, b) => a[key]?.localeCompare(b[key]));
  } else {
    if (type === "number") {
      return array.sort((a, b) => b[key] - a[key]);
    }
    return array.sort((a, b) => b[key]?.localeCompare(a[key]));
  }
};

function compare(a: any, b: any, key: string, sortBy: string) {
  if (key === "dob") {
    if (new Date(a[key]) < new Date(b[key]) && sortBy === "desc") {
      return -1;
    }
    if (new Date(a[key]) > new Date(b[key]) && sortBy === "asc") {
      return 1;
    }
    return 0;
  }
  if (a[key] < b[key] && sortBy === "desc") {
    return -1;
  }
  if (a[key] > b[key] && sortBy === "asc") {
    return 1;
  }
  return 0;
}

export const pagination = ({
  array,
  page = 1,
  limit = 20,
}: IPaginationArray) => {
  const page_number = page && page > 0 ? page + 1 : page === 0 ? 1 : page;
  return array.slice((page_number - 1) * limit, page_number * limit);
};

export const cutFileName = (filename: string, maxLength: number, start: number, end: number): string => {
  if (filename.length <= maxLength) {
    return filename;
  }
  const truncatedName = filename.slice(0, start);
  return `${truncatedName}...${filename.slice(-end)}`;
}

/* set profile details */
export const setProfileDetail = (profile: any) => {
  const data = profile;
  data.email = profile.user.email;
  data.version = profile.user?.version;
  data.mobile_number = parsePhoneNumberformat(
    profile.user.country_code,
    profile.user.mobile_number
  );
  data.secondary_phone = profile?.secondary_code? parsePhoneNumberformat(
    profile.secondary_code,
    profile.secondary_phone
  ) : null;
  return {
    firstname: data?.firstname && data?.firstname,
    middlename: data?.middlename && data?.middlename,
    lastname: data?.lastname && data?.lastname,
    email: data?.email && data?.email,
    gender: data?.gender && data?.gender,
    ssn: data?.ssn && formatSsn(data?.ssn),
    dob: data?.dob ? dayjs(data?.dob) : null as any,
    mobile_number: data?.mobile_number && data?.mobile_number,
    country_of_birth: data?.country_of_birth
      ? ({ name: data?.country_of_birth, value: data?.country_of_birth } as any)
      : null,
    home_phone: data?.home_phone && data?.home_phone,
    office_phone: data?.office_phone && data?.office_phone,
    english_fluency: (data?.english_fluency).toString(),
    preferred_language : data?.preferred_language && data?.preferred_language,
    other_language:data?.other_language && data?.other_language,
    tnc_affiliation: data?.tnc_affiliation && data?.tnc_affiliation,
    address_line_1: data?.address_line_1 && data?.address_line_1,
    address_line_2: data?.address_line_2 && data?.address_line_2,
    city: data?.city && data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
      version:data?.version && data?.version,
    zip_code: data?.zip_code && formatZipcode(data.zip_code),
  }
}

/* set agent details */
export const setAgentDetail = (data: any) => {
  return {
    dfs_insurence_license: data?.dfs_insurence_license,
    business_name: data?.business_name,
    insurance_class: data?.insurance_class,
    business_email: data?.business_email,
    office_number: data?.office_number,
    federal_id: data?.federal_id,
    address_line_one: data?.address_line_one,
    b_address_line_two: data?.b_address_line_two,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
        firstname: data?.user?.profile?.firstname,
    middlename: data?.user?.profile?.middlename,
    lastname: data?.user?.profile?.lastname,
    dob: "",
    mobile_number: data?.user?.country_code + "," + data?.user?.mobile_number,
    email: data?.user?.email,
    gender: data?.gender,
    english_fluency: "",
    secondary_phone: "",
    tnc_affiliation: "",
    address_line_1: data?.address_line_1,
    address_line_2: data?.address_line_2,
    city: data?.city,
    state: null,
    zip_code: formatZipcode(data?.zip_code),
  }
}

/* set driver details */
export const setDriverDetail = (data: any) => {
  return {
    hack_license_number: data.hack_license_number,
    hack_license_expiration_date: data?.hack_license_expiration_date ? dayjs(data?.hack_license_expiration_date) : null as any,
    base_name: data.base_name,
    dmv_license_number: data.dmv_license_number,
    dmv_license_state: data?.dmv_license_state
      ? (data?.dmv_license_state as any)
      : null,
    dmv_license_issue_date: data?.dmv_license_issue_date ? dayjs(data?.dmv_license_issue_date) : null as any,
    dmv_license_expiration_date: data?.dmv_license_expiration_date ? dayjs(data?.dmv_license_expiration_date) : null as any,
    // firstname: data?.user?.profile?.firstname,
    // middlename: data?.user?.profile?.middlename,
    // lastname: data?.user?.profile?.lastname,
    // dob: "",
    // mobile_number: data?.user?.country_code + "," + data?.user?.mobile_number,
    // email: data?.user?.email,
    // gender: data?.gender,
    // english_fluency: "",
    // secondary_phone: "",
    // tnc_affiliation: "",
    // address_line_1: data?.address_line_1,
    // address_line_2: data?.address_line_2,
    // city: data?.city,
    // state: null,
    // zip_code: formatZipcode(data?.zip_code),
  }
}


/* set fleet vehicle */
export const setFleetOperator = (data: any) => {
  return {
    company_name: data?.company_name,
    fleet_email: data?.email,
    fleet_office_number: data?.fleet_office_number,
    bic: data?.bic,
    address: data?.address,
    address_line2: data?.address_line2,
    fleet_city: data?.city,
    fleet_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    fleet_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    fleet_zip_code: formatZipcode(data?.zip_code),
  }
}

/* set vehicle onwer */
export const setVehicleOnwer = (data: any) => {
  return {
    business_name: data?.business_name,
    bic_number: data?.bic_number,
    office_number: data?.office_number,
    address_line_one: data?.address_line_1,
    b_address_line_two: data?.b_address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
  }
}


/* set service provider details */
export const setServiceProvider = (data: any) => {
  return {
    business_name: data?.business_name,
    tax_id: data?.tax_id,
    office_number: data?.office_number,
    npi_number: data?.npi_number,
    medical_provider_type: data?.medical_provider_type
      ? ({ name: data?.medical_provider_type, value: data?.medical_provider_type } as any)
      : null,
    service_provider_type: data?.service_provider_type,
    attorney_license_number: data?.attorney_license_number,
    address_line_one: data?.address_line_1,
    address_line_two: data?.address_line_2,
    business_city: data?.city,
    business_state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    business_country: data?.country
      ? ({ name: data?.country, value: data?.country } as any)
      : null,
    business_zip_code: formatZipcode(data.zip_code),
  }
}

/* set vehicle permit details */
export const setVehilcePermit = (data: any) => {
  return {
    vehicle_permit_license_number: data?.vehicle_permit_license_number,
    vehicle_tlc_license_type: data?.vehicle_tlc_license_type,
    permit_expiration_date: data?.permit_expiration_date ?
      dayjs(data?.permit_expiration_date) : null as any,
    dmv_license_plate_number: data?.dmv_license_plate_number,
    fhv_vehicle_license_number: data?.fhv_vehicle_license_number,
    license_name: data?.license_name,
    vehicle_class: data?.vehicle_class,
    base_number: data?.base_number,
    base_name: data?.base_name,
    base_type: data?.base_type,
    mobile_number: data?.mobile_number,
    website: data?.website,
    address_line_1: data?.address_line_1,
    address_line_2: data?.address_line_2,
    city: data?.city,
    state: data?.state
      ? ({ name: data?.state, value: data?.state } as any)
      : null,
    zip_code: formatZipcode(data?.zip_code),
  }
}

/* set dongle and obd */
export const setDongleandODB = (data: any) => {
  return {
    device_type: data?.device_type,
    dongle_make: data?.dongle_make,
    dongle_model: data?.dongle_model,
    serial_number: data?.serial_number,
    obd_parameter_id: data?.obd_parameter_id,
    obd_version: data?.obd_version,
  }
}

export const profilePayload = (payload: any) => {
  const phoneNumber = parsePhoneNumber(payload.mobile_number);
  const secondaryNumber = parsePhoneNumber(payload.secondary_phone)
  return {
    ...payload,
    /* take state and country of birth object to string */
    state: payload?.state?.name,
    country_of_birth: payload?.country_of_birth?.name,
    /* string value to convert the boolean */
    english_fluency: Boolean(payload?.english_fluency),
    /* sperate the mobile_number to country and mobile number */
    country_code: phoneNumber?.countryCode,
    mobile_number: phoneNumber?.mobileNumber,
    secondary_code: secondaryNumber?.countryCode,
    secondary_phone: secondaryNumber?.mobileNumber,
    ssn: payload?.ssn?.replace(/-/g, ''),
    zip_code: payload?.zip_code?.replace(/-/g, ''),
    /* convert the date formet */
    dob: dayjs(payload.dob).format('YYYY-MM-DD')
  }
}

export const payloadSplit = (payload: any) => {
  try {
    const profile: any = {
      firstname: payload?.firstname,
      lastname: payload?.lastname,
      middlename: payload?.middlename,
      gender: payload?.gender,
      dob: payload?.dob,
      country_code: payload?.country_code,
      mobile_number: payload?.mobile_number,
      office_phone: payload?.office_phone,
      home_phone: payload?.home_phone,
      secondary_code: payload?.secondary_code,
      secondary_phone: payload?.secondary_phone,
      tnc_affiliation: payload?.tnc_affiliation,
      email: payload.email,
      country_of_birth: payload?.country_of_birth,
      ssn: payload?.ssn,
      english_fluency: Boolean(payload?.english_fluency),
      address_line_1: payload?.address_line_1,
      address_line_2: payload?.address_line_2,
      city: payload?.city,
      state: payload?.state,
      zip_code: payload?.zip_code,
    }

    /* remove the profile data in payload */
    delete payload.firstname;
    delete payload.lastname;
    delete payload.middlename;
    delete payload.gender;
    delete payload.dob;
    delete payload.country_code;
    delete payload.mobile_number;
    delete payload.office_phone;
    delete payload.home_phone;
    delete payload.email;
    delete payload.country_of_birth;
    delete payload.ssn;
    delete payload.english_fluency;
    delete payload.secondary_code;
    delete payload.secondary_phone;
    delete payload.tnc_affiliation
    delete payload.address_line_1;
    delete payload.address_line_2;
    delete payload.city;
    delete payload.state;
    delete payload.zip_code;

    if (!profile.email) {
      delete profile.email
    }

    /* remaining payload details */
    const others = { ...payload }

    return {
      profilePayload: profile,
      others: others,
    };
  } catch (err) {
    return {
      profilePayload: null,
      others: null,
    };
  }
}

/* set driver detail from the legacy */
export const setLegacyDriver = (data: any) => {
  console.log(data)
  return {
    firstname: data?.firstname,
    middlename: data?.middlename,
    lastname: data?.lastname,
    dmv_license_state:data?.dmv_license_state,
    dmv_license_number: data?.dmv_license_number ,
    dmv_license_issue_date: data?.dmv_license_issue_date ?
      dateFnc(data?.dmv_license_issue_date) : null as any,
    dmv_license_expiration_date: data?.dmv_license_expiration_date ?
      dateFnc(data?.dmv_license_expiration_date) : null as any,
    base_name: data?.base_name,
    hack_license_number: data?.hack_license_number,
    hack_license_expiration_date: data?.hack_license_expiration_date
      ? dateFnc(data?.hack_license_expiration_date) : null as any,
  }
}

/* set vehicle detail from the legacy */
export const setLegacyVehicle = (data: any) => {
  return {
    vehicle_identification_number: data?.vehicle_identification_number,
    vehicle_class: data?.vehicle_class,
    fuel_type: data?.fuel_type,
    vehicle_make: data?.vehicle_make,
    vehicle_model: data?.vehicle_model,
    vehicle_year: data?.vehicle_year
      ? ({ name: data?.vehicle_year, value: data?.vehicle_year } as any)
      : null,
    weight: data?.weight,
    safety_rating: data?.safety_rating,
    seating_capacity: data?.seating_capacity,
    vehicle_license_number: data?.vehicle_license_number,
    dmv_license_plate_number: data?.dmv_license_plate_number,
    tlc_permit_license_number: data?.tlc_permit_license_number,
    vehicle_owner: data?.vehicle_owner,
  }
}


/* set vehicle detail from the legacy */
export const setVehicleAdditionalDetails = (data: any) => {
  const vehicle = {
    driver_cam: data?.driver_cam,
    obd_plug_in: data?.obd_plug_in ? data?.obd_plug_in : null,
    dongle: data?.dongle ? data?.dongle : null,
    black_box: (data?.black_box),
    gps_navigation: (data?.gps_navigation),
    bluetooth: (data?.bluetooth),
    automatic_braking: (data?.automatic_braking),
    lane_depature_control: (data?.lane_depature_control),
    forward_collision_warning: data?.forward_collision_warning,
    lane_departure_warning: data?.lane_departure_warning,
    anti_lock_braking_system: (data?.anti_lock_braking_system),
    electronic_stability_control: (data?.electronic_stability_control),
    traction_control: (data?.traction_control),
    tyre_pressure_monitoring_system: (data?.tyre_pressure_monitoring_system),
    automatic_pedestrian_alert_sound: (data?.automatic_pedestrian_alert_sound),
    automatic_crash_notification: (data?.automatic_crash_notification),
    blind_spot_warning: (data?.blind_spot_warning),
    event_data_recorder: (data?.event_data_recorder),
    wheel_chair_accessibility: (data?.wheel_chair_accessibility),
    vehicle_owner: data?.vehicle_owner,
    vehicle_driver: data?.vehicle_driver,
    vehicle_fleet: data?.vehicle_fleet,
    vehicle_registrant: data?.vehicle_registrant,
    dmv_license_plate_number: data?.dmv_license_plate_number,
    tlc_permit_license_number: data?.tlc_permit_license_number,
    current_insurance_carrier: data?.current_insurance_carrier? { name: data?.current_insurance_carrier, value: data?.current_insurance_carrier } : null,
  }
  console.log('vehicle-----', vehicle);
  return booleanToStringsInObject(vehicle);
}