import defaultRole from "constant/config.role";
import { IPopupItems } from "interface/redux.interface";

export const driver: any[] = [
  {
    path:  `${defaultRole.profile}/profile`,
    title: "Profile",
    icon: "profile",
  },
  // {
  //   path: `${defaultRole.profile}/resetpassword`,
  //   title: "Reset Password",
  //   icon: "settings",
  // },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const driverVerify: IPopupItems[] = [
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const admin: IPopupItems[] = [
  {
    path: `${defaultRole.admin}/profile`,
    title: "Profile",
    icon: "profile",
  },
  // {
  //   path: `${defaultRole.admin}/resetpassword`,
  //   title: "Reset Password",
  //   icon: "settings",
  // },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const agent: IPopupItems[] = [
  {
    path: `${defaultRole.profile}/profile`,
    title: "Profile",
    icon: "profile",
  },
  // {
  //   path: `${defaultRole.profile}/resetpassword`,
  //   title: "Reset Password",
  //   icon: "settings",
  // },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];
export const vehicleOwnerType = [
  { value: 'vehicle_owner', name: 'Vehicle Owner' },
  { value: 'registrant', name: 'Registrant' },
]

export const IRoles = {
  DRIVER: "profile",
  REP: "employee",
  LAWYER: "service_provider",
  ADMIN: "admin",
  VerifyDriver: "VerifyDriver",
  profile: 'profile',
  RepLawer: 'rep_service_provider'
}
 
export const Roles: any = {
  Driver: "driver",
  Lawyer: "lawyer",
  Rep: "employee",
  Admin: "admin",
  profile: "profile"
}

/* digital wallet roles */
export const DigitalWalletRole = {
  ADMIN: "admin",
  PROFILE: "profile",
  DRIVER: "driver"
}

/* digital wallet roles in array */ 
export const DigitalWalletArrayRole = [
  'driver', 'agent', 'fleetOperator', 'serviceProvider', 'vehicleOwner', 'profile']

/* payment current status filter */
export const PaymentFilterStatus = [
  { name: "Payment Due", value: "Pending" },
  { name: "Payment Completed", value: "Paid" },
];

/* payment pay method */
export const PaymentMethodFilterStatus = [
  { name: "Online", value: "Online" },
  { name: "Offline", value: "Offline" },
]

export const TICKET_STATUS = {
  CREATED: "Ticket Created",
  ASSIGNED: "Representative Assigned",
  INPROGRESS: "Inprogress",
  ONHOLD: "On Hold",
  LAWYER_REVIEW: "Pending Lawyer Review",
  TICKET_REJECTED: "Ticket Rejected",
  TICKET_APPROVED: "Ticket Accepted",
  // PAYMENT_PENDING: "Payment due",
  PAYMENT_DONE: "Payment completed",
  LAWYER_ASSIGNED: "Lawyer Assigned",
  WORK_INPROGRESS: "Work In Progress",
  IN_REVIEW: "Decision In Review",
  DECISION_AWARDED: "Decision Awarded",
  CLOSED: "Closed",
  WAITING: "Waiting"
};

/* project end url Name */
export const projectEndURLName = {
  digitalCredentialing: 'dc'
}