import { DigitalWalletRole } from "utils/constant.utils";

const profile = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  appName: "My Wallet",
  backServices: `${process.env.REACT_APP_DC_REDIRECTION_URL}login?url=/profile/services_menu`,
  walletAddress: "",
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard/coming_soon`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard/coming_soon`],
    },
    {
      id: `services`,
      title: `Services`,
      type: `collapse`,
      url: `${process.env.REACT_APP_DC_REDIRECTION_URL}login?url=/profile/services_menu`,
      href: true,
      icon: `Dns`,
      breadcrumbs: false,
      activeItem: [`/services/coming_soon`],
    },
    {
      id: `member_portal`,
      title: `Member Portal`,
      type: `item`,
      url: `/member_portal/coming_soon`,
      icon: `userPerson`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/member_portal/coming_soon`],
    },
    {
      id: `my_business`,
      title: `My Business`,
      type: `item`,
      url: `/my_business/coming_soon`,
      icon: `AccountBalance`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/my_business/coming_soon`],
    },
    // {
    // 	id: `reports`,
    // 	title: `Reports`,
    // 	type: `item`,
    // 	url: `/reports/coming_soon`,
    // 	icon: `userReport`,
    // 	breadcrumbs: false,
    // 	href: false,
    // 	activeItem: [`/reports/coming_soon`],
    // },
  ],
};

export const profileSubMenu = [
  {
    id: `badges`,
    title: `Badges`,
    type: `item`,
    url: `/profile_badges`,
    icon: `badge`,
    breadcrumbs: false,
    activeItem: [
      `/profile_badges`,
      `/${DigitalWalletRole.PROFILE}/service_provider_edit`,
      `/${DigitalWalletRole.PROFILE}/agent_edit`,
      `/${DigitalWalletRole.PROFILE}/fleet_operator_edit`,
      `/${DigitalWalletRole.PROFILE}/vehicle_owner_edit`,
      `/${DigitalWalletRole.PROFILE}/driver_edit`,
    ],
  },
  {
    id: `coupons`,
    title: `Coupons`,
    type: `item`,
    url: `/${DigitalWalletRole.PROFILE}/coupons`,
    icon: `Coupon`,
    breadcrumbs: false,
    activeItem: [`/${DigitalWalletRole.PROFILE}/coupons`],
  },
  {
    id: `tokens`,
    title: `Tokens`,
    type: `item`,
    url: `/${DigitalWalletRole.PROFILE}/tokens_list`,
    icon: `TokenIcon`,
    breadcrumbs: false,
    activeItem: [
      `/${DigitalWalletRole.PROFILE}/tokens_list`,
      `/${DigitalWalletRole.PROFILE}/token`,
    ],
  },
];

export default profile;
