import { Suspense, lazy } from "react"
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import { DigitalWalletArrayRole, DigitalWalletRole } from "utils/constant.utils";

/* guest routes */
const PageNotFound = lazy(() => import("pages/pagenotfound"));
const ComingSoon = lazy(() => import("layout/hoc/coming-soon"));

const Login = lazy(() => import("pages/authentication/Login"));

/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));

/* benefits */
const Benefits = lazy(() => import("pages/benefits"));

/* view all notification component */
const ViewAllNotifications = lazy(() => import("pages/notification"))

/* admin component */
const AirdropsList = lazy(() => import("pages/admin/airdrops/airdrops-list"));
const AirdropCreate = lazy(() => import("pages/admin/airdrops/airdrop-create"));
const AirdropCouponView = lazy(() => import("pages/admin/airdrops/airdrop-view/CouponAirdropView"));
const AirdropTokenView = lazy(() => import("pages/admin/airdrops/airdrop-view/TokenAirdropView"));

/* coupons */
const CouponsList = lazy(() => import("pages/admin/coupons/coupons-list"));
const CouponCreate = lazy(() => import("pages/admin/coupons/coupon-create"));
const CouponView = lazy(() => import("pages/admin/coupons/coupon-view"));
const CouponHistory = lazy(() => import("pages/admin/coupons/coupon-history"));
const CouponRedeemed = lazy(() => import("pages/admin/coupons/coupon-redeemed"));

/* tokens */
const TokenList = lazy(() => import('pages/admin/tokens'))
const TokenHistory = lazy(() => import('pages/admin/tokens/TokenHistory'))

/* coupons categories */
const CouponCategoriesList = lazy(() => import("pages/admin/coupon-categories/coupons-categories-list"));
const CouponCategoryCreate = lazy(() => import("pages/admin/coupon-categories/coupon-categories-create"));
const CouponCategoryView = lazy(() => import("pages/admin/coupon-categories/coupon-categories-view"));

/* profile user component */
const ProfileView = lazy(() => import("../layout/pages/profile-details/profile-basic-details"));
const ProfileEdit = lazy(() => import("../layout/pages/profile-details/profile-basic-edit"));
const BadgeMenu = lazy(() => import("pages/profile-details/badges-menu"));
const AgentBadgeEdit = lazy(() => import("pages/profile-details/badges-details-edit/AgentDetailsEdit"));
const DriverBadgeEdit = lazy(() => import("pages/profile-details/badges-details-edit/DriverDetailsEdit"));
const FleetOperatorBadgeEdit = lazy(() => import("pages/profile-details/badges-details-edit/FleetOperatorEdit"));
const ServiceProviderBadgeEdit = lazy(() => import("pages/profile-details/badges-details-edit/ServiceProviderEdit"));
const VehicleOwnerBadgeEdit = lazy(() => import("pages/profile-details/badges-details-edit/VehicleOwnerEdit"));

/* coupon */
const ActiveCoupon = lazy(() => import("pages/profile/coupons"));

/* token */
const UserTokenList = lazy(() => import('pages/profile/token/TokenList'))
const UserTokenView = lazy(() => import("pages/profile/token/TokenView"))


/* digital wallet roles */
const { ADMIN, PROFILE, DRIVER } = DigitalWalletRole;

//Guest role authentication
const GuestRoute = () => {
  return <Outlet />
};

const ProtectedUserRoute = (props: any) => {
  let auth: any = useAppSelector((state) => state.user.user);
  let location = useLocation();
  return props?.role?.some((r: any) => auth?.role?.includes(r)) ? (
    <Outlet />
  ) : <Navigate to="/login" state={{ from: location }} replace />
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="not-found" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          {/* Guest routes */}
          <Route element={<GuestRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/coupons" element={<Benefits />} />
          </Route>
          <Route element={<ProtectedUserRoute role={[PROFILE]} />}>
            <Route element={<Layout />} >
              <Route path={`/coming_soon`} element={<ComingSoon />} />
              <Route path={`/dashboard/coming_soon`} element={<ComingSoon />} />
              <Route path={`/services/coming_soon`} element={<ComingSoon />} />
              <Route path={`/site_admin/coming_soon`} element={<ComingSoon />} />
              <Route path={`/member_portal/coming_soon`} element={<ComingSoon />} />
              <Route path={`/my_business/coming_soon`} element={<ComingSoon />} />
              <Route path={`/reports/coming_soon`} element={<ComingSoon />} />
              <Route path={`/help/coming_soon`} element={<ComingSoon />} />
              <Route path={`/coming_soon_1`} element={<ComingSoon />} />
              <Route path={`/coming_soon_2`} element={<ComingSoon />} />

              {/* view all notification route */}
              <Route path={`/view-all-notification`} element={<ViewAllNotifications />} />


              {/* admin routes */}
              <Route element={<ProtectedUserRoute role={[ADMIN]} />}>
                <Route path={`${DigitalWalletRole.ADMIN}/airdrops_list`} element={<AirdropsList />} />
                <Route path={`${DigitalWalletRole.ADMIN}/airdrop_create`} element={<AirdropCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/airdrop_edit/:id`} element={<AirdropCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/airdrop_coupon_view/:id`} element={<AirdropCouponView />} />
                <Route path={`${DigitalWalletRole.ADMIN}/airdrop_token_view/:id`} element={<AirdropTokenView />} />

                {/* coupon pages */}
                <Route path={`${DigitalWalletRole.ADMIN}/coupons_list`} element={<CouponsList />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_create`} element={<CouponCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_edit`} element={<CouponCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_view`} element={<CouponView />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_history`} element={<CouponHistory />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_redeemed`} element={<CouponRedeemed />} />

                {/* token pages */}
                <Route path={`${DigitalWalletRole.ADMIN}/tokens_list`} element={<TokenList />} />
                <Route path={`${DigitalWalletRole.ADMIN}/token_history`} element={<TokenHistory />} />

                {/* coupon category pages */}
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_categories`} element={<CouponCategoriesList />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_categories_create`} element={<CouponCategoryCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_categories_edit`} element={<CouponCategoryCreate />} />
                <Route path={`${DigitalWalletRole.ADMIN}/coupon_categories_view`} element={<CouponCategoryView />} />
              </Route>

              {/* profile user routes*/}
              <Route element={<ProtectedUserRoute role={[PROFILE]} />}>

                {/* profile details page */}
                <Route path={`/profile_details`} element={<ProfileView />} />
                <Route path={`/profile_edit`} element={<ProfileEdit />} />

                {/* token pages */}
                <Route path={`${DigitalWalletRole.PROFILE}/coupons`} element={<ActiveCoupon />} />

                {/* token pages */}
                <Route path={`${DigitalWalletRole.PROFILE}/tokens_list`} element={<UserTokenList />} />
                <Route path={`${DigitalWalletRole.PROFILE}/token`} element={<UserTokenView />} />


                {/* badges routes */}
                <Route path={`/profile_badges`} element={<BadgeMenu />} />
                <Route path={`${DigitalWalletRole.PROFILE}/agent_edit`} element={<AgentBadgeEdit />} />
                <Route path={`${DigitalWalletRole.PROFILE}/driver_edit`} element={<DriverBadgeEdit />} />
                <Route path={`${DigitalWalletRole.PROFILE}/fleet_operator_edit`} element={<FleetOperatorBadgeEdit />} />
                <Route path={`${DigitalWalletRole.PROFILE}/service_provider_edit`} element={<ServiceProviderBadgeEdit />} />
                <Route path={`${DigitalWalletRole.PROFILE}/vehicle_owner_edit`} element={<VehicleOwnerBadgeEdit />} />

              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  )
}

export default RoutePath;