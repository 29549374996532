import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./localstorage.helper";
import { useAppDispatch, useAppSelector } from "../store/store";
import { commonService } from "forms/helpers/common.service";
import { clearStore } from "utils/redux.utils";
import { parsePhoneNumberformat } from "forms/hooks/customHook";
import { setClaimPublichCouplonRedux, setMenuItemsRedux, setPopupItemsRedux, setStateListsRedux, setSubMenuItemsRedux, setUserRedux } from "store/slice/user.slice";
import { DigitalWalletArrayRole, DigitalWalletRole, projectEndURLName } from "utils/constant.utils";
import menuItemsDW from "utils/menu-items";
import popupItems from "utils/popup-item";
import { profileSubMenu } from "utils/menu-items/profile";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import SweetAlertComponent from "component/HOC/Alert";


export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useLocalStorage(
    "at-dw-token",
    sessionStorage.getItem("at-dw-token")
  );
  const navigate = useNavigate();
  /* get redux data */
  const user = useAppSelector((state) => state.user.user);
  const claimPublishCouponDetails = useAppSelector((state) => state.user.claimPublishCoupon);

  const dispatch = useAppDispatch();

  const [countryCode, setCountrycode] = useState<string>("US");

  useEffect(() => {
    if (token) {
      getUser();
      getStateList();
    }
  }, []);

  const getStateList = async () => {
    try {
      const stateResponse: any = await commonService.postService(
        `/v1/public/state_list/${countryCode}`,
        {}, false, projectEndURLName.digitalCredentialing
      );
      if (!!stateResponse) {
        let stateList: any = stateResponse.data.data;
        dispatch(setStateListsRedux(stateList));
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* handle claim publish coupon */
  const claimPublishCouponApi = async (userId: string) => {
    try {
      let payload = {
        org: process.env.REACT_APP_TITTLE,
        user_Id: userId,
        coupon_id: claimPublishCouponDetails?.coupon_id
      }
      const claimPublishCouponResponse = await commonService
        .postService(`/coupon/claim_from_fhtg`, { ...payload });
      if (!!claimPublishCouponResponse) {
        if (claimPublishCouponResponse?.data?.status == 204) {
          SweetAlertComponent({
            status: claimPublishCouponResponse?.data?.status,
            message: claimPublishCouponResponse?.data?.data,
            title: "Information",
            onSuccess: () => {
              dispatch(setClaimPublichCouplonRedux({}));
              navigate(`/${DigitalWalletRole.PROFILE}/coupons`)
            },
          });
        } else {
          dispatch(setClaimPublichCouplonRedux({}));
          navigate(`/${DigitalWalletRole.PROFILE}/coupons`);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(setClaimPublichCouplonRedux({}));
      Failed(
        errorMessage(error, "Something went wrong, please try again later")
      );
    }
  }

  /* handle external share claim coupon */
  const externalShareClaimCouponApi = async (userId: string, token_id?: string) => {
    try {
      let payload = {
        token_id: token_id
      }
      const externalShareClaimCouponResponse = await commonService
        .postService(`/coupon/transfer_from_external_share`, { ...payload });
      if (!!externalShareClaimCouponResponse) {
        if (externalShareClaimCouponResponse?.data?.status == 204) {
          SweetAlertComponent({
            status: externalShareClaimCouponResponse?.data?.status,
            message: externalShareClaimCouponResponse?.data?.message,
            title: "Information",
            onSuccess: () => {
              sessionStorage.setItem("external_coupon_token_id", '');
              navigate(`/${DigitalWalletRole.PROFILE}/coupons`)
            },
          });
        } else {
          sessionStorage.setItem("external_coupon_token_id", '');
          navigate(`/${DigitalWalletRole.PROFILE}/coupons`);
        }
      }
    } catch (error) {
      console.log(error);
      sessionStorage.setItem("external_coupon_token_id", '');
      navigate(`/profile_badges`);
      Failed(
        errorMessage(error, "Something went wrong, please try again later")
      );
    }
  }

  const getUser = async (navigateParams?: any, isInitialRoute?: boolean) => {
    try {
      const user: any = await commonService.postService(
        `/register/get_profile_details`, {}
      );
      const userData = user?.data?.data;

      let roles: any = [];
      if (userData?.roles?.length) {
        userData?.roles.map((b: any) => roles.push(b.role))
      }

      /* user data value */
      const setUserData = {
        email: userData?.email,
        firstname: userData.first_name,
        lastname: userData.last_name,
        dob: userData?.dob,
        id: userData.id,
        mobile_number:
          parsePhoneNumberformat(
            userData?.country_code,
            userData?.mobile_number
          ) || "",
        role: roles,
      };
      dispatch(setUserRedux(setUserData));
      let menus = [];
      let popupItem = [];
      if (roles.includes(DigitalWalletRole.ADMIN)) {
        menus = menuItemsDW[DigitalWalletRole.ADMIN];
        popupItem = popupItems[DigitalWalletRole.ADMIN];
        dispatch(setSubMenuItemsRedux(menus[0]?.children[5]?.children));
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux(menuItemsDW.admin));
        if (navigateParams) {
          await getStateList();
          navigate(navigateParams);
          return;
        }
        if (isInitialRoute) {
          await getStateList();
          navigate(`/${DigitalWalletRole.ADMIN}/airdrops_list`);
          return;
        }
      } else if (roles?.some((value: string) => DigitalWalletArrayRole?.includes(value))) {
        menus = menuItemsDW[DigitalWalletRole.PROFILE];
        popupItem = popupItems[DigitalWalletRole.PROFILE];

        /* extract the wallet address */
        const regex = /CN=([a-zA-Z0-9-]+)::/;
        const match = userData?.wallet_address?.match(regex);
        let menu = menus[0];
        menu = {
          ...menu,
          walletAddress: match[1]
        }
        dispatch(setSubMenuItemsRedux(profileSubMenu));
        dispatch(setPopupItemsRedux(popupItem));
        dispatch(setMenuItemsRedux([menu]));
        /* get external coupon id using session storage */
        let external_coupon_token_id: any = sessionStorage.getItem('external_coupon_token_id');
        if (!!external_coupon_token_id) {
          external_coupon_token_id = JSON.parse(external_coupon_token_id);
        }
        if (!!claimPublishCouponDetails?.coupon_id) {
          await claimPublishCouponApi(match[1]);
          return;
        }
        if (!!external_coupon_token_id?.token_id) {
          await externalShareClaimCouponApi(match[1], external_coupon_token_id?.token_id);
          return;
        }
        if (navigateParams) {
          await getStateList();
          navigate(navigateParams);
          return;
        }
        if (isInitialRoute) {
          await getStateList();
          navigate(`/profile_badges`);
        }
      }
    } catch (err) {
      console.log("err", err);
      clearStore();
      setToken(null);
      navigate("/login");
    }
  };

  const login = async (data: any) => {
    setToken(data?.token);
    await getUser();
  };

  const logout = () => {
    clearStore();
    setToken(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user,
      getUser,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};
