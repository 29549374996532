export default function componentStyleOverrides(theme: any) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "4px",
          padding: "0.7rem 2rem",
          boxShadow: "none",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root.Mui-selected": {
            border: "1px solid #1b00ff",
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.paper,
            backgroundColor: "#283891 !important",
            borderRadius: "0",
            "&:hover": {
              backgroundColor: "#283891",
            },
            "& .MuiListItemIcon-root": {
              color: theme.paper,
            },
          },
          "&:hover": {
            backgroundColor: theme.divider,
            // color: theme.menuSelected,
            "& .MuiListItemIcon-root": {
              // color: theme.menuSelected
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.main-menu': {
            color: theme.darkTextPrimary,
            paddingTop: '5px',
            paddingBottom: '5px',
            '& svg': {
              color: theme?.iconColor,
              fontSize: '25px',
            },
            '&.Mui-selected': {
              color: theme.backgroundDefault,
              backgroundColor: theme?.iconColor,
              // backgroundColor: '#0090D6',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: theme?.iconColor,
              },
              '& .MuiListItemIcon-root': {
                color: theme.menuSelected
              },
              '& svg': {
                color: theme.backgroundDefault,
              },
            },
            '&:hover': {
              backgroundColor: theme.divider,
              // color: theme.menuSelected,
              '& .MuiListItemIcon-root': {
                // color: theme.menuSelected
              }
            }
          },
          "&.sub-menu": {
            color: theme.backgroundDefault,
            paddingTop: "5px",
            paddingBottom: "5px",
            "& svg": {
              color: theme.backgroundDefault,
              fontSize: "25px",
            },
            "&.Mui-selected": {
              color: theme.backgroundDefault,
              backgroundColor: theme.colors.primaryMain,
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: theme.colors.primaryMain,
              },
              '&.Mui-selected': {
                color: theme.backgroundDefault,
                backgroundColor: theme.colors.buttonColor,
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: theme.colors.buttonColor
                },
                '& .MuiListItemIcon-root': {
                  color: theme.backgroundDefault
                },
                '& svg': {
                  color: theme.backgroundDefault,
                },
              },
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.paper,
          background: theme.colors?.primary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
  };
}
