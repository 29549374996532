import axios from "axios";
import { keycloakValue } from "pages/authentication/keycloak/useKeyCloak";
import { clearStore } from "utils/redux.utils";

const mainAxios = process.env.REACT_APP_DW_END_URL;
const ssoAxios = process.env.REACT_APP_SSO_URL;
const dcAxios = process.env.REACT_APP_DC_END_URL;
axios.defaults.baseURL = mainAxios;
export const IsBaseURL = (setCommonCode = true, URLName: any = 'dw') => {
  axios.defaults.baseURL = URLName == 'dw' ? mainAxios : 
  URLName == 'dc' ? dcAxios  : ssoAxios ;
} 

export const SetAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
}


const onRequestFulfilled = async (config: any) => {
  let token = sessionStorage.getItem('at-dw-token');
  if (token && token != 'undefined') {
    if(keycloakValue?.updateToken) {
      const refreshToken = await keycloakValue.updateToken(300).then((ses : any) => ses).catch((err: any) => console.log('err', err));
      if(refreshToken) 
      sessionStorage.setItem(
        "at-dw-token",
        keycloakValue?.token || ''
        );
    }
    token = sessionStorage.getItem('at-dw-token');
    config.headers ={
      ...config.headers,
      'api-secret-key': process.env.REACT_APP_API_SECRET_KEY
    }  
    config.headers.Authorization = `Bearer ${token}`
  }
  return Promise.resolve(config);
}

const onRequestRejected = (error: any) => {
  console.log("errorrr", error);
  return Promise.reject(error);
}

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config)
}

const onResponseRejected = (error: any) => {
  console.log("errorrr", error);
  if (error?.response?.status === 401) {
    clearStore();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  }
  return Promise.reject(error);
}

export const resetSession = () => {
  sessionStorage.removeItem('at-dc-auth');
}