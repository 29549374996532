import './App.scss';
import { useAppDispatch, useAppSelector } from './store/store';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from 'asset/themes';
import NavigationScroll from 'container/layout/NavigationScroll';
import { SetAxiosDefauls } from 'helpers/interceptor';
import { KeycloackContext } from 'pages/authentication/keycloak/useKeyCloak';
import { useContext, useEffect, useState } from 'react';
import RoutePath from 'routes';
import { io } from 'socket.io-client';
import { setDWNotiCount } from 'store/slice/user.slice';
import { clearStore } from 'utils/redux.utils';

function App() {
  const customization = useAppSelector((state: any) => state.customization);
  const { keycloakValue } = useContext(KeycloackContext);

  /* get user details using redux */
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  SetAxiosDefauls();

  useEffect(() => {
    keycloakValue.onAuthLogout = () => {
      console.log('User logged out');
      clearStore();
      localStorage.clear();
    };

    return () => {
      // Clean up event listeners on component unmount
      keycloakValue.onAuthLogout = null;
    };
  }, []);

  const handleUserConnection = (e: any) => {
    console.log("User Joined");
  };

  useEffect(() => {
    if (user) {
      const socketConnect = io(
        `${process.env.REACT_APP_DW_END_URL}/socket`,
        {
          path: "/api/socket",
          auth: {
            token: `Bearer ${sessionStorage.getItem("at-dw-token")}`,
          },
          transports: ["websocket", "polling"],
        }
      );
      socketConnect.on("connect", () => {
        if (sessionStorage.getItem("at-dw-token")) {
          socketConnect?.emit("notification-count", (data: any) => {
            dispatch(setDWNotiCount(data?.count))
          });
          socketConnect?.emit("joined", handleUserConnection);
        }
      });

      socketConnect.on("receive-notification", () => {
        socketConnect.emit("notification-count", (data: any) => {
          dispatch(setDWNotiCount(data?.count))
        });
      })
    }
  }, [user]);


  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline>
            <NavigationScroll>
              <RoutePath />
            </NavigationScroll>
          </CssBaseline>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
